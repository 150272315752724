<template>
  <div>
    <TopNav/>
    <el-row class="dynamic">
      <el-col :span="22" :offset="1">
        <h6 class="title">磐石动态</h6>
      </el-col>
      <el-col :span="22" :offset="1">
        <div 
          v-for="(item,index) in panshDynamicList" 
          :key="index" 
          class="dynamicdet"  
          @click="handleDynamic(index)"
        >
          <div class="deta">
            <img :src="window.ipConfig.baseUrl + item.image" />
          </div>
          <div class="detb">
            <h6>{{item.title}}</h6>
            <p>{{item.content}}</p>
          </div>
          <div class="clearfix"></div>
        </div>
      </el-col>
    </el-row>

    <!-- 详情弹窗 -->
    <el-dialog
      :title="panshDynamic.title"
      :visible.sync="dynamicDialogVisible"
      width="90%"
      class="panshDynamicD">
      <div v-html="panshDynamic.imageAndContent" class="DynamicDetail">
      </div>
    </el-dialog>

    <FooterMobile/>
  </div>
</template>

<script>
import {psDynamic} from '@/api'
import TopNav from '../../components/topNav.vue';
import FooterMobile from '../../components/footerMobile.vue';
export default {
    name: "DynamicMobile",
    data() {
      return {
        panshDynamicList: [],
        dynamicDialogVisible:false,
        panshDynamic:{}
      };
    },
    created(){
      this.getPsDynamic();
    },
    mounted(){
      setTimeout(() => {
        $("body,html").animate({scrollTop: 0},500);
      }, 500);
    },
    methods: {
      // 获取磐石动态
      async getPsDynamic() {
        const result = await psDynamic();
        this.panshDynamicList = result.data;
      },
      // 展示动态详情
      handleDynamic(index){
        this.panshDynamic = this.panshDynamicList[index]
        this.dynamicDialogVisible = true
      },
    },
    computed:{
      window: function () {
        // `this` 指向 vm 实例
        return window
      }
    },
    components: { TopNav, FooterMobile }
}
</script>

<style scoped>
/*重置样式*/
li{
  list-style: none;
}
a{
  text-decoration: none;
}
*{
  font-size: 3vw;
}
>>> .panshDynamicD .el-dialog__title{
  display: block;
  width: 95%;
}
img{
  width: 100%;
}
  .dynamic{padding-top: 17%;}
  .dynamicdet{display: flex;padding: 3vw;background: #144F7D;margin: 2vw 0;font-size: initial;}
  .clearfix{clear: both;}
  .title{font-weight: normal;font-size: 4.65vw;border-left: 5px solid #E32121;padding-left: 10px;}
  .deta{width: 40%;float: left;}
  .deta img{width: 100%;}
  .detb{float: left;width: 60%;padding-left: 5%;}
  .detb h6{font-size: 14px;color: #fff;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;}
  .detb p{font-size: 12px;color: #fff;line-height: 18px;height: 36px;overflow: hidden;}
  >>> .DynamicDetail p {
  font-size: 3.5vw;
    text-indent: 2em;
    color: #333;
  }
  >>> .DynamicDetail img{
    max-width: 100%;
    margin: 0 auto;
    display: block;
    height: auto;
    margin: 10px 0;
  }
</style>